import { FC, Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { ROUTE_URLS } from '../consts/route_urls';
import ProgramSelector from '../../_mApp/auth/components/ProgramSelector';

const PrivateRoutes = () => {
    const Crm = lazy(() => import('../modules/Crm'));
    const Tasks = lazy(() => import('../modules/Tasks'));
    const Los = lazy(() => import('../modules/Los'));
    const Disbursal = lazy(() => import('../modules/Disbursal'));
    const Accounting = lazy(() => import('../modules/Accounting'));
    const Lms = lazy(() => import('../modules/Lms'));
    const CoLending = lazy(() => import('../modules/CoLending'));
    const Admin = lazy(() => import('../modules/Admin'));
    const Dashboard = lazy(() => import('../modules/Dashboard'));
    const BorrowerApp = lazy(() => import('../modules/BorrowerApp'));
    const Reports = lazy(() => import('../modules/Dashboard/DashboardReports'));
    const Seeder = lazy(() => import('../modules/Seeder'));
    const BrandGuide = lazy(() => import('../modules/BrandGuide'));

    const Kyc = lazy(() => import('../modules/Kyc'));
    const Credit = lazy(() => import('../modules/Credit'));

    useEffect(() => {
        document.body.style.backgroundImage = '';
        return () => {};
    }, []);

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route
                    path='tasks/*'
                    element={
                        <SuspensedView>
                            <Tasks />
                        </SuspensedView>
                    }
                />
                <Route
                    path='auth/*'
                    element={<Navigate to={ROUTE_URLS.dashboard} />}
                />
                <Route
                    path='switch-program'
                    element={<ProgramSelector />}
                />
                <Route
                    path='dashboard/*'
                    element={
                        <SuspensedView>
                            <Dashboard />
                        </SuspensedView>
                    }
                />
                <Route
                    path='reports/*'
                    element={
                        <SuspensedView>
                            <Reports />
                        </SuspensedView>
                    }
                />
                <Route
                    path='kyc/*'
                    element={
                        <SuspensedView>
                            <Kyc />
                        </SuspensedView>
                    }
                />
                <Route
                    path='credit/*'
                    element={
                        <SuspensedView>
                            <Credit />
                        </SuspensedView>
                    }
                />

                {/* <Route path='admin/*' element={<SuspensedView><Plugins /></SuspensedView>} /> */}
                {/* <Route path='admin/*' element={<SuspensedView><Unr /></SuspensedView>} /> */}
                {/* <Route path='admin/constants/*' element={<SuspensedView><Constants /></SuspensedView>} /> */}
                {/* <Route path='admin/configs/*' element={<SuspensedView><Configs /></SuspensedView>} /> */}
                <Route
                    path='home/*'
                    element={
                        <SuspensedView>
                            <BorrowerApp />
                        </SuspensedView>
                    }
                />
                <Route
                    path='admin/*'
                    element={
                        <SuspensedView>
                            <Admin />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crm/*'
                    element={
                        <SuspensedView>
                            <Crm />
                        </SuspensedView>
                    }
                />
                <Route
                    path='los/*'
                    element={
                        <SuspensedView>
                            <Los />
                        </SuspensedView>
                    }
                />
                <Route
                    path='disbursal/*'
                    element={
                        <SuspensedView>
                            <Disbursal />
                        </SuspensedView>
                    }
                />
                <Route
                    path='accounting/*'
                    element={
                        <SuspensedView>
                            <Accounting />
                        </SuspensedView>
                    }
                />
                <Route
                    path='lms/*'
                    element={
                        <SuspensedView>
                            <Lms />
                        </SuspensedView>
                    }
                />
                <Route
                    path='co_lending/*'
                    element={
                        <SuspensedView>
                            <CoLending />
                        </SuspensedView>
                    }
                />
                <Route
                    path='seeder/*'
                    element={
                        <SuspensedView>
                            <Seeder />
                        </SuspensedView>
                    }
                />
                <Route
                    path='brand-guide/*'
                    element={
                        <SuspensedView>
                            <BrandGuide />
                        </SuspensedView>
                    }
                />
                <Route
                    path='*'
                    element={<Navigate to='/error/404' />}
                />
            </Route>
        </Routes>
    );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 5,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
