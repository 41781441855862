import { generatePath } from 'react-router-dom';
import { flattenExpanded } from './obj';
import { beautify } from './str';
import { ROUTES } from '../consts/routes';
import { transform } from './transform';

export const buildUrl = (rootUrl, args = {}, options = {}) => {
    let tmp = { ...args };
    if (!rootUrl) return '';
    let { url, queryParams } = getQueryParams(rootUrl);
    let origin = '';
    try {
        origin = new URL(url).origin;
    } catch (error) {}

    if (origin && url.indexOf(origin) !== -1) {
        url = url.replace(origin, '');
    }

    if (url[url.length - 1] !== '/') {
        url += '/';
    }
    tmp = flattenExpanded(tmp);
    if (tmp) {
        if (!options.ignoreExtra) {
            Object.keys(tmp).forEach((key) => {
                if (url.indexOf(':' + key) === -1 && tmp[key]) {
                    queryParams[key] = tmp[key];
                }
            });
        }
        url = parseUrl(url, tmp);
        if (Object.keys(queryParams).length) {
            url += '?' + new URLSearchParams(queryParams).toString();
        }
    }
    if (rootUrl.indexOf('refund') !== -1) {
        console.log({
            rootUrl,
            args,
            options,
            origin,
            url,
            tmp,
        });
    }
    return origin + url;
};

export const getValue = (data, key = '', join = true, defaultValue = '') => {
    const tmp = { ...data };
    try {
        if (!key) {
            return tmp;
        }
        if (Array.isArray(key)) {
            const values = [];
            key.forEach((row) => {
                values.push(
                    row.split('.').reduce((o, i) => {
                        return o[i];
                    }, tmp)
                );
            });
            return (
                (join ? values.filter((n) => n).join(', ') : values.filter((n) => n)) ||
                defaultValue
            );
        } else {
            return key.split('.').reduce((o, i) => o[i], tmp) || defaultValue;
        }
    } catch (error) {
        return defaultValue;
    }
};

export const getOptionValues = (data) => {
    return data.map((item) => item.value);
};

export const getInitial = (str) => {
    return str?.[0] ? str[0].toUpperCase() : '~';
};

export const parseUrl = (url, params, queryParams) => {
    if (url) {
        const tmp = [];
        url.split('/').forEach((x) => {
            if (x.indexOf(':') !== -1 && x.indexOf('.') !== -1) {
                const k = x.replaceAll('.', '_').replaceAll(':', '');
                params[k] = getValue(params, x.replaceAll(':', ''));
                x = x.replaceAll('.', '_');
            }
            tmp.push(x);
        });
        url = tmp.join('/');
    }

    try {
        if (!url || !params) {
            return '';
        } else if (url.indexOf('~') !== -1) {
            return url.replaceAll('~', params);
        }
        url = generatePath(url, params);
        if (queryParams && Object?.keys(queryParams)?.length > 0) {
            const tmpQueryParams = {};
            Object.entries({ ...queryParams }).forEach(([key, value]) => {
                if (value) {
                    tmpQueryParams[key] = getValue(params, value);
                }
            });
            url = buildUrl(url, tmpQueryParams);
        }
        return url;
    } catch (error) {
        return url;
    }
};

export const getQueryParams = (url) => {
    const queryParams = {};
    const queryString = url.split('?')[1];
    let baseUrl = url;

    if (queryString) {
        const paramPairs = queryString.split('&');
        paramPairs.forEach((pair) => {
            const [key, value] = pair.split('=');
            queryParams[key] = decodeURIComponent(value);
        });
        baseUrl = url.split('?')[0];
    }

    return { url: baseUrl, queryParams };
};

export const slugify = (str, delimiter = '_') => {
    if (!str) return '';
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, delimiter)
        .replace(/^-+|-+$/g, '');
};

export const _delete = (obj, props) => {
    if (typeof props === 'string') {
        props = [props];
    }
    props.forEach((prop) => {
        if (obj[prop]) {
            delete obj[prop];
        }
    });
    return obj;
};

export const fixId = (data) => {
    if (Array.isArray(data)) {
        data.forEach((row, index) => {
            data[index] = fixId(row);
        });
    } else {
        if (data) {
            Object.entries(data).forEach(([key, value]) => {
                if (key === '_id') {
                    data['id'] = data['_id'];
                }
                if (value && typeof value === 'object') {
                    data[key] = fixId(value);
                }
            });
        }
    }
    return data;
};

export const packDotNotation = (arr) => {
    const result = [];

    arr.forEach((str) => {
        const parts = str.split('.');
        let current = result;

        parts.forEach((part, index) => {
            const existing = current.find((item) => item.name === part);

            if (existing) {
                current = existing.children;
            } else {
                const newItem = { name: part, children: [] };
                current.push(newItem);
                current = newItem.children;
            }
        });
    });
    console.log(result);
    return result;
};

export const breadcrumbs = (data, map) => {
    const pathnames = data.split('/').filter((crumb) => crumb);
    const list = ['admin', 'identity', 'crm', 'dashboard', 'los', 'disbursal', 'unr', 'lenders'];
    const breadcrumbList = pathnames
        .map((path, index) => {
            return {
                title: map && map[path] ? map[path] : beautify(path),
                path: `/${pathnames.slice(0, index + 1).join('/')}`,
                isSeparator: list.includes(path),
                isActive: false,
            };
        })
        .filter((crumb) => !crumb.isSeparator);
    breadcrumbList.unshift({
        title: 'Home',
        path: ROUTES.dashboard.url,
        isSeparator: false,
        isActive: false,
    });
    return breadcrumbList;
};

export const perc = (value, total) => {
    if (!value || isNaN(value) || !total) {
        return 0;
    }
    return !total ? '∞' : ((value / total) * 100).toFixed(2) * 1;
};

export const amountWords = (num, symbol = true) => {
    let val = 0;
    let mutliplier = 1;
    if (num < 0) {
        mutliplier = -1;
        num *= -1;
    }
    if (num > 999 && num < 100000) {
        val = mutliplier * parseFloat((num / 1000).toFixed(2)) + 'K';
    } else if (num >= 10000000) {
        val = mutliplier * parseFloat((num / 10000000).toFixed(2)) + ' Cr';
    } else if (num >= 100000) {
        val = mutliplier * parseFloat((num / 100000).toFixed(2)) + ' L';
    } else if (num <= 999) {
        val = mutliplier * num;
    }
    return symbol ? '₹' + val : val;
};

export const PMT = (ir, np, pv, fv, type) => {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt;
};

// reverse -> EMI Amount (Monthly),  Interest Rate, Tenure -> max Loan amount

export const percClass = (value) => {
    if (isNaN(value)) return 'secondary';
    if (value > 80) return 'success';
    if (value > 40) return 'warning';
    return 'danger';
};

export const percColor = (value) => {
    if (value > 80) return '#5ac360';
    if (value > 40) return '#ecc33c';
    return '#e4445c';
};

export const statusBadge = (status, padding = 'py-3 px-4') => {
    return (
        <span className={`badge ${padding} badge-light-${transform('class', status)} d-inline`}>
            {beautify(status)}
        </span>
    );
};
