/* eslint-disable jsx-a11y/anchor-is-valid */
import { bindActionCreators } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { listConstants } from '../../../app/modules/Constants/_constants.service';
import { getCompany, getCompanyConfig } from '../../../app/modules/Unr/_unr.service';
import { creators } from '../../../app/state';
import { useAuth } from '../core/Auth';
import { getUserByToken, login } from '../core/_requests';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const loginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

const initialValues = {
    username: '',
    password: '',
};

export function Login() {
    const [loading, setLoading] = useState(false);
    const [recaptcha, setRecaptcha] = useState('');
    const { saveAuth, setCurrentUser } = useAuth();
    const dispatch = useDispatch();
    const { setConstants } = bindActionCreators(creators, dispatch);

    const initConstants = async () => {
        const data = await listConstants();
        setConstants(data);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                const { data: auth } = await login(values.username, values.password, recaptcha);
                saveAuth(auth);
                const { data: user } = await getUserByToken(true);
                if (!user.company) {
                    user.company = {};
                }
                if (user.company_id) {
                    user.company = await getCompany(user.company_id, { active_services: true });
                    if (!user.company) {
                        user.company = {};
                    }
                    user.company.config = await getCompanyConfig(
                        user?.company?.owned_by || user?.company_id
                    );
                }
                console.log(user);
                setCurrentUser(user);
                await initConstants();
            } catch (error) {
                saveAuth(undefined);
                setStatus('The login detail is incorrect');
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        localStorage.removeItem('user_type');
    }, []);

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'>
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-gray-900 fw-bolder mb-3'>Sign In</h1>

                <div className='text-gray-500 fw-semibold fs-6'>to your Dashboard</div>
            </div>
            {/* begin::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
                <input
                    placeholder='Username'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                    type='text'
                    name='username'
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.username}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                        {/* end::Label */}
                        {/* begin::Link */}
                        <Link
                            to='/auth/forgot-password'
                            className='link-primary fs-6 fw-bolder'
                            style={{ marginLeft: '5px' }}>
                            {/* Forgot Password ? */}
                        </Link>
                        {/* end::Link */}
                    </div>
                </div>
                <input
                    type='password'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                <input
                    type='hidden'
                    name='recaptcha'
                    value={recaptcha}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_ID}>
                    <GoogleReCaptcha
                        onVerify={(token) => {
                            setRecaptcha(token);
                        }}
                    />
                </GoogleReCaptchaProvider>
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}>
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span
                            className='indicator-progress'
                            style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <div className='text-garay-500 text-center fw-semibold fs-6'>
                    <a
                        href='/auth/login/borrower'
                        className='link-primary'>
                        {' '}
                        Login as a Borrower
                    </a>
                </div>
            </div>
        </form>
    );
}
