import { DICT } from '../consts/dict';
import { ROUTES } from '../consts/routes';
import { arrayDiff, objectify } from './arr';
import { getValue } from './utils';
import Pluralize from 'pluralize';

export const beautify = (str, flag = true) => {
    // TODO: Lookup Constants first
    if (!flag) {
        return str;
    }
    if (str && typeof str === 'string' && str.indexOf('.') !== -1) {
        const chunks = str.split('.');
        str = chunks[chunks.length - 1];
    }
    if (typeof str === 'boolean') {
        return str ? 'Yes' : 'No';
    } else if (str && typeof str === 'string') {
        // Get word from dictionary
        const word = DICT.en[str];
        if (word) {
            return word;
        }

        // If is_, remove and add suffix ?
        if (str.indexOf('is_') === 0) {
            return beautify(str.replace('is_', '') + '?');
        }

        if (str.indexOf('min') === str.length - 3 && str.length >= 3) {
            str = 'min_' + str.replace('min', '');
        }

        if (str.indexOf('max') === str.length - 3 && str.length >= 3) {
            str = 'max_' + str.replace('max', '');
        }

        // If nothing found
        const arr = str.replace(/_/g, ' ').toLowerCase().split(' ');
        for (let i = 0; i < arr.length; i++) {
            const word = DICT.en[arr[i]];
            if (word) {
                arr[i] = word;
                continue;
            }
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].substring(1);
        }
        return arr.join(' ');
    }
    return '';
};

export const isZero = (val) => {
    return val === 0 || val === '0';
};

export const isJson = (str) => {
    if (typeof str === 'object') {
        return true;
    }
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
};

export const validateConditionItem = (condition, data) => {
    let result = true;
    let [key, operator, value] = condition
        .match(/([a-zA-Z._]+)(>=|<=|!=|==|[<>]=?|=|:)(-?.+)/)
        .slice(1);
    let output = getValue(data, key.replaceAll('.length', ''));
    if (key.indexOf('.length') !== -1) {
        output = output.length;
    }
    result =
        result && (output === undefined || compareValues(output, value, operator) ? true : false);
    return result;
};

export const validateCondition = (conditions, data) => {
    let result = true;
    if (typeof conditions === 'string') {
        return validateConditionItem(conditions, data);
    }
    conditions.forEach((condition) => {
        if (Array.isArray(condition)) {
            let tmpResult = false;
            condition.forEach((item) => {
                if (!tmpResult) {
                    tmpResult = validateConditionItem(item, data);
                }
            });
            result = tmpResult;
        } else {
            if (result) {
                result = validateConditionItem(condition, data);
            }
        }
    });
    return result;
};

export const compareValues = (value1, value2, operator) => {
    value1 = normalizeBoolean(value1);
    value2 = normalizeBoolean(value2);
    switch (operator) {
        case '>':
            return value1 > value2;
        case '<':
            return value1 < value2;
        case '>=':
            return value1 >= value2;
        case '<=':
            return value1 <= value2;
        case '!=':
            return value1 != value2;
        case '=':
        case ':':
        case '==':
            if (typeof value2 !== 'boolean' && value2.indexOf(',') !== -1) {
                value2 = value2.split(',');
                return value2.indexOf(value1) !== -1;
            }
            return value1 == value2;
        default:
            throw new Error('Invalid operator');
    }
};

function normalizeBoolean(value) {
    if (value === 'true') {
        return true;
    } else if (value === 'false') {
        return false;
    }
    return value;
}

export const plural = (word, count) => {
    return Pluralize(word, count);
};

export const singular = (word) => {
    return Pluralize.singular(word);
};

export const filename = (url) => {
    return url.split('/').pop().split('#')[0].split('?')[0];
};

export const amount = (value, symbol = true, printZero = true) => {
    value = unAmount(value);
    if (value === undefined || value === null || value === '' || isNaN(value)) {
        value = 0;
    }
    const formatter = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    };
    if (symbol) {
        formatter.style = 'currency';
        formatter.currency = 'INR';
    }
    if (parseFloat(value) === 0 && printZero === false) {
        return '';
    }
    const suffix = value?.toString()?.endsWith('.') ? '.' : '';
    const formattedValue = parseFloat(value).toLocaleString('en-IN', formatter) + suffix;
    return formattedValue.replace(/\.00$/, '');
};

export const unAmount = (value) => {
    const symbol = '₹';
    if (value === undefined || value === null || value === '' || value === symbol) {
        return '';
    }
    value = value?.toString()?.replaceAll(symbol, '')?.replaceAll(',', '');
    return value?.endsWith('.') ? value : parseFloat(value);
};

export const bureauScoreClass = (score) => {
    if (score > 750) {
        return '#0cb559';
    } else if (score >= 700) {
        return '#75C245';
    } else if (score >= 650) {
        return '#8449ff';
    } else if (score >= 600) {
        return '#EEAF48';
    } else if (score >= 500) {
        return '#E77D3B';
    } else {
        return '#E24333';
    }
};

export const addSuffix = (obj, value, suffix) => {
    const suffixMap = {
        percentage: '%',
        fixed: '',
    };
    let suffixValue = getValue(obj, suffixMap[suffix] || suffix);
    suffixValue = suffixMap[suffixValue] || suffixValue;
    if (suffixValue) {
        value += ' ' + suffixValue;
    }
    return value;
};

export const beautifyOptionSelection = (selected, options, prefix) => {
    if (options.length === selected.length) {
        return prefix || 'All';
    } else if (options.length - selected.length > 0 && options.length - selected.length < 4) {
        const diff = arrayDiff(options, selected);
        return `${prefix} except ${diff.map((option) => beautify(option)).join(', ')}`;
    }
    return selected.map((option) => beautify(option)).join(', ');
};

export const maskString = (str, mask = true, start = 3, end = 2) => {
    if (!str) {
        return '';
    }
    if (mask) {
        const strLength = str.length;

        // Ensure the start index is within the bounds of the string
        if (start < 0 || start > strLength) {
            return 'Invalid start index';
        }

        // Calculate the actual end index from the end of the string
        const actualEnd = strLength - end;

        // Ensure the actual end index is within the bounds of the string
        if (actualEnd < 0 || actualEnd < start) {
            return 'Invalid end index';
        }

        // Extract the parts of the string that will remain unchanged
        const prefix = str.slice(0, start);
        const suffix = str.slice(actualEnd);

        // Create the masked part
        const maskedPart = '*'.repeat(actualEnd - start);

        // Combine the parts and return the masked string
        return prefix + maskedPart + suffix;
    }
    return str;
};

export const elipsify = (str, length = 20) => {
    if (str.length <= length) {
        return str;
    }
    return str.substring(0, length) + '...';
};

export const metaTitle = (path) => {
    path = path.replace(/\/+$/, '');
    if (!path) {
        path = window.location.pathname;
    }
    const urls = Object.values(ROUTES);
    const mapping = objectify(urls, 'url');
    return mapping?.[path]?.title;
};

export const getPersonTag = (businessType) => {
    if (businessType === 'P') {
        return 'owner';
    } else if (businessType === 'F') {
        return 'partner';
    }
    return 'director';
};

export const completeAddress = (data) => {
    const addressParts = [];
    if (data.address_line) addressParts.push(data.address_line);
    if (data.city) addressParts.push(data.city);
    if (data.state) addressParts.push(data.state);
    if (data.pincode) addressParts.push(data.pincode);
    return addressParts.join(', ');
};
